
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                
































.c-gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.c-gallery__item {
    --width: 100%;

    width: var(--width);
}

