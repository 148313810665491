
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                




































































.o-heading__title {

    em {
        @extend .t-special;
        margin-right: .2em;
    }

    &:nth-child(n+2) {
        margin-top: 1.25rem;
    }
}


