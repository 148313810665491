
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                








































































































































































































.p-projects {}

.p-projects__grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: var(--grid-gutter);

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{md("sm")} {
        flex-basis: auto;
        margin-right: auto;
        margin-left: auto;

        .c-card.is-featured {
            grid-column: 1 / 3;
        }
    }

    @media #{md("md")} {
        grid-template-columns: repeat(3, 1fr);

        .c-card.is-featured {
            grid-column: 1 / 4;
        }
    }
}

.p-projects__filters {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem -.5rem;

    .c-btn {
        margin: .25rem .5rem;

        &.is-active {
            pointer-events: none;

            .c-btn__ripple {
                transform: scale(1);
            }
        }

        .js-magnet-hit {
            --hit-x: -.1em;
        }
    }
}


/*=========================================
=            Filter Animations            =
=========================================*/

// Animation
// .anim-project-grid-enter {
//     transition: opacity .4s ease-in, transform .4s $in-quad;
// }

// .anim-project-grid-enter,
// .anim-project-grid-leave-to {
//     transform: translate(0, 2em);
//     opacity: 0;
// }

// .anim-project-grid-leave-active {
//     position: absolute;
//     z-index: -1;
// }

