
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                






















































.b-content-list {
}

.b-content-list__text {

    .c-list__item {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 1em;

        &:before {
            content: "/";
            display: block;
            width: 1em;
            flex-shrink: 0;
        }
    }
}

.b-content-list__cards {
    display: grid;
    grid-gap: 2em var(--grid-gutter-half);
    grid-template-columns: repeat(2, minmax(0, 1fr));

    &:not(:first-child) {
        margin-top: 2rem;
    }

    .o-asset {
        margin-bottom: 1rem;
    }

    @media #{md("xs")} {

        .o-heading {
            padding: 1rem;
        }
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media #{md("md")} {

        .b-content.-large & {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

