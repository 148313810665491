
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                






































































.b-instagram {
    display: grid;
    grid-gap: var(--grid-gutter);
    grid-template-columns: 100%;

    @media #{md("md")} {
        align-items: end;
        grid-template-columns: 1fr 2fr;
    }
}

.b-instagram__grid {
    display: grid;
    grid-gap: var(--grid-gutter);

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);
    }
}

.c-card.-standalone {
    margin-top: 1em;

    @media #{md("md", "max")} {
        display: none;
    }
}


