
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                




































































.b-content {

    .b-content-text:nth-child(n+2),
    .b-content-headline:nth-child(n+2) {
        margin-top: 1.25rem;
    }

    .b-content-buttons:nth-child(n+2) {
        margin-top: 2rem;
    }
}

