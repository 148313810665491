
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                






































































































































































































































































































/*==============================
=            Footer            =
==============================*/


.l-footer {
    z-index: 1;
}

.l-footer__wave {
    top: -1px;
    background-color: var(--color-bg);
}


/*----------  Social Block  ----------*/

.o-social {
    overflow: hidden;

    // If no figure
    .o-wave:nth-child(2) {
        position: relative;
        left: calc(50% - 50vw);
        width: 100vw;
        margin-top: vh(10);
    }
}

.o-social__box {
    padding: 2em 1.5em;
    background-color: $color-light;
    box-shadow: $box-shadow;

    @media #{md("xs")} {
        padding: 3em 2em;
    }

    @media #{md("sm")} {
        padding: 6em 3em;
    }
}

.o-social__list,
.o-contact__list {
    display: grid;
    grid-gap: 1em;
    margin-top: 2.5em;

    @media #{md("xs")} {
        grid-row-gap: 2em;
        margin-top: 2.5em;
    }

    @media #{md("sm")} {
        grid-row-gap: 3em;
    }

    @media #{md("md")} {
        grid-row-gap: 4em;
    }
}

.o-social__list {
    grid-template-columns: repeat(2, 1fr);

    @media #{md("sm")} {
        margin-top: 1.5em;
    }
}

.o-social__item {}

.o-social__link {
    display: inline-flex;
    align-items: center;

    .o-icon.-linkedin {
        top: -.2em;
    }

    span {
        display: block;
        margin-left: 1em;
    }
}

.o-social__fig {
    margin-top: -2em;
    width: 80%;

    img {
        @include img;
    }

    @media #{md("xs")} {
        margin-top: -3em;
    }

    @media #{md("sm")} {
        margin-top: -15%;
        width: 60%;
    }
}


/*----------  Contact block  ----------*/


.o-contact {
    padding-bottom: 2em;

    .o-heading:nth-child(n+2) {
        margin-top: 2em;
    }

    @media #{md("xs")} {
        padding-bottom: 3em;
    }

    @media #{md("sm")} {
        // padding-bottom: 6em;
    }

    @media #{md("md")} {

        .o-heading:nth-child(n+2) {
            margin-top: 3em;
        }
    }
}

.o-contact__heading {

    @media #{md("sm")} {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
}

.o-contact__lang {
    display: inline-block;
    margin-top: 2em;
    padding: 0;

    @media #{md("sm")} {
        // margin-top: auto;
        margin-bottom: .3em;
    }
}

.o-contact__lang,
.o-contact__link {
    @include linear-gradient(to bottom, transparent calc(100% - .1em), currentColor 0);
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.o-contact__list {

    @media #{md("sm")} {
        margin-top: 0;
    }
}

.o-contact__link {
    display: inline;
    line-height: 1.6;
}

.o-contact__label {
    margin-bottom: 1em;
}

/*=====  End of Contact block  ======*/


.o-contact__logo {
    display: block;
    overflow: hidden;

    .o-icon.-mambo {
        display: block;
        width: 100%;
        height: 0;
        padding-top: calc(39/223 * 100%);
        margin-bottom: -1%;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

