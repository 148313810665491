/*=========================================
=            Typographic bases            =
=========================================*/

// Include fontface
@include fontfaces($fontfaces, $font-dir);

body {
    font-family: ff("main");
    line-height: $line-height;
    color: $font-color;
}


/*==============================
=            Titles            =
==============================*/

.t-t1,
.t-t2,
.t-t3,
.t-t4,
.t-t5 {
    font-weight: 400;
    line-height: 1.15;
}

.t-t1,
.t-cms h1 {
    @include responsive-type(32, 65, 480, 1600);
    // font-size: $font-size-large;

    // @media #{md("sm")} {
    //     font-size: $font-size-x-large;
    // }
}

.t-t2,
.t-cms h2 {
    @include responsive-type(26, 45, 480, 1600);
    // font-size: $font-size-medium;

    // @media #{md("sm")} {
    //     font-size: $font-size-large;
    // }
}

.t-t3,
.t-cms h3 {
    @include responsive-type(20, 35, 480, 1600);
    // font-size: $font-size-regular;

    // @media #{md("sm")} {
    //     font-size: $font-size-medium;
    // }
}

.t-t4,
.t-cms h4 {
    @include responsive-type(14, 20, 480, 1600);
    // font-size: $font-size-xx-small;

    // @media #{md("sm")} {
    //     font-size: $font-size-x-small;
    // }
}


.t-t5,
.t-cms h5 {
    @include responsive-type(12, 16, 480, 1600);
    // font-size: $font-size-xxx-small;

    // @media #{md("sm")} {
    //     font-size: $font-size-xx-small;
    // }
}


.t-sub,
.t-cms h6 {
    line-height: 1.25;
    font-family: ff("supline");
    // font-size: $font-size-xxx-small;
    font-size: .6rem;
    font-weight: 700;
    text-transform: uppercase;
}


.t-special {
    display: inline-block;
    letter-spacing: 0;
    line-height: 1;
    font-family: ff("special");
    font-size: 120%;
    font-weight: 400;
    text-transform: initial;

    transform: rotate(-4deg);
    transform-origin: 0 0;
}


.t-content {

    > *:nth-child(n+2) {
        margin-top: 1rem;

        &.c-btn {
            margin-top: 2rem;
        }
    }
}



/*==========================================
=            CMS content styles            =
==========================================*/


.t-cms {

    p,
    ul,
    ol,
    blockquote {
        margin-bottom: $line-height * 1em;
    }

    ul {

        li {
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;

            &:before {
                content: "/";
                width: 2em;
            }
        }
    }

    a {

        &:hover {
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}


/*=====================================
=            Miscellaneous            =
=====================================*/


small {
    font-size: 0.75em;
}

sup {
    vertical-align: super;
    font-size: 0.75em;
}

a {
    color: inherit;
    text-decoration: none;
}

ul {
    @include reset-list;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 400;
}
