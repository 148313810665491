
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                






















































































.c-card.-project-upcoming {

    .c-card__asset {
        width: 100%;
        height: 0;
        padding-top: 100%;

        img {
            transform: scale(1.1);
        }

        &:hover {

            img {
                transform: scale(1);
            }
        }
    }
}


