
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                














.o-spacer {
    display: block;
    width: 100%;
    height: 1.5rem;

    @media #{md("xs")} {
        height: 2.5rem;
    }

    @media #{md("sm")} {
        height: 4rem;
    }
}

