
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                







































































/*====================================
=            Color swatch            =
====================================*/


.o-color-swatch {
    text-align-last: auto;
}

.o-color-swatch__box {
    display: block;
    width: 100%;
    height: 0;
    padding-top: 100%;

    &.-light {
        box-shadow: $box-shadow;
    }
}

.o-color-swatch__label {
    display: block;
    margin-top: 1em;
}

