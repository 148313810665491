
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                






















































































.b-results {
}

.b-results__link {
    display: block;
    width: 100%;
    height: 100%;
}

.b-results__image {
    height: 100%;
    overflow: hidden;
}

.b-results__content {
    padding: 1.5rem;
    position: absolute;
    z-index: 3;
    bottom: 0;
    padding-right: 20%;
    transition: 150ms ease-in-out;
}

.b-results__sub {
    margin-bottom: 1rem;
}

