
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                























































































































































.c-card.-project {

    .c-card__asset {
        z-index: 2;
        clip-path: none;
        background-color: $color-light;
        border-radius: 0;
        box-shadow: $box-shadow;

        &.-cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            .c-card__bg {
                clip-path: circle(25%);
            }
        }
    }

    img {
        @include img;
        transform: scale(.9);
        transition: transform .4s ease-out;
    }

    .c-card__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        clip-path: circle(25%);
        transition: clip-path .4s ease-out;
    }

    .c-card__inner {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 1.5em;
    }

    .c-card__list {
        z-index: 1;
    }

    .c-card__content {
        z-index: 3;
        padding-bottom: 0;

        &.-light {
            transition: color .2s ease-in;
        }
    }

    @at-root a#{&} {

        &:hover {

            .c-card__bg {
                clip-path: circle(100%);
                transition: clip-path .6s ease-out;
            }

            img {
                transform: scale(1);
                transition: transform .3s ease-out 0s;
            }

            .c-card__content.-light {
                color: $color-light;
                transition: color .2s ease-out;
            }
        }
    }

    @media #{md("xs")} {

        &.is-featured {
            z-index: 3;
            background-color: $color-light;
            box-shadow: $box-shadow;
            margin-top: 10%;
            margin-bottom: 10%;

            .c-card__asset {
                width: calc(1/3 * 100%);
                margin-left: 18%;
                background-color: transparent;
                box-shadow: none;
                overflow: visible;
                pointer-events: none;

                .c-card__bg {
                    position: relative;
                    opacity: 0;
                    visibility: hidden;
                }

                .c-card__preview {
                    position: absolute;
                    top: 50%;
                    left: calc((100 - 220)/2 * 1%);
                    width: 220%;
                    transform: translate(0, -50%);
                }

                &.-single {

                    .c-card__preview {
                        position: relative;
                        top: auto;
                        left: auto;
                        width: 100%;
                        opacity: 0;
                        visibility: hidden;
                        transform: none;
                    }
                    .c-card__bg {
                        position: absolute;
                        top: -10%;
                        left: calc((100 - 170)/2 * 1%);
                        width: 170%;
                        height: 0;
                        padding-top: 170%;
                        opacity: 1;
                        visibility: visible;
                        clip-path: circle(50%);

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%) scale(1);
                            transition: transform .3s ease-out 0s;
                        }
                    }
                }
            }

            .c-card__inner {
                right: 0;
                left: auto;
                display: flex;
                justify-content: flex-end;
                height: 100%;
                margin-left: auto;
                width: calc(1/3 * 100%);
            }

            .c-card__list {
                display: none;
            }

            img {
                transform: none;
            }

            @at-root a#{&} {

                &:hover {

                    .c-card__asset.-single {

                        .c-card__bg {
                            clip-path: circle(50%);
                        }

                        img {
                            transform: translate(-50%, -50%) scale(1.05);
                        }
                    }
                }
            }
        }
    }

    @media #{md("sm")} {

        &.is-featured {

            .c-card__inner {
                justify-content: space-between;
            }

            .c-card__list {
                display: block;
            }
        }
    }
}


