/*=============================
=            Cards            =
=============================*/

.c-card {
	display: block;
}

.c-card__asset {
	clip-path: circle(50%);
	border-radius: .5em;
	overflow: hidden;
	transition: clip-path .3s ease-in-out;

	img {
		transform: scale(1.1);
		transition: transform .3s ease-in-out;
	}

	&:hover {
		clip-path: circle(70%);
		transition: clip-path .3s ease-out;

		img {
			transform: scale(1);
			transition: transform .3s ease-out;
		}
	}
}

.c-card__content {
	padding-top: 1em;
	padding-bottom: 1em;
}

.c-card__sub {
	display: block;
	margin-top: .5em;

	@extend .t-sub;
}
