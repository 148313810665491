
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                




















































































































.b-projects {

    @media #{md("xs", "max")} {

        .c-slider {
            margin-top: 2em;
        }
    }
}

