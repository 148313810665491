
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                















































.o-icon {
    display: inline-block;
    vertical-align: middle;

    svg {
        display: block;
        width: var(--svg-width, 1em);
        height: calc(var(--svg-width, 1em) * var(--svg-ratio, 1));
        fill: currentColor;
    }
}


/*----------  SVG Sizes  ----------*/

.svg-mambo {
    --svg-width: 5.5em;
    --svg-ratio: 39/223;
}

.svg-mambomambo {
    --svg-width: 5.5em;
    --svg-ratio: 88/216;
}

.svg-palm {
    --svg-width: 1.25em;
    --svg-ratio: 35/39;
}

.svg-buoy {
    --svg-width: 1em;
}

.svg-circles {
    --svg-width: 1.25em;
}

.svg-award {
    --svg-width: 1em;
    --svg-ratio: 20/17;
}

.svg-shoutout {
    --svg-width: 1.5em;
    --svg-ratio: 17/21;
}

.svg-github,
.svg-behance,
.svg-codepen,
.svg-dribbble,
.svg-linkedin,
.svg-facebook,
.svg-instagram {
    --svg-width: 1em;
}

.svg-github {
    --svg-ratio: 11/13;
}

.svg-behance {
    --svg-ratio: 33/50;
}

.svg-codepen {
    --svg-ratio: 31/30;
}


