
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                








































































































































.b-tools {

    .c-content {
        text-align: center;
    }
}

.b-tools__other {

    .c-list {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        margin-top: 1rem;
        margin-bottom: 2rem;

        @media #{md("sm")} {
            margin-top: 2rem;
            margin-bottom: 4rem;
        }

        @media #{md("md")} {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }

        @media #{md("lg")} {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}


/*=================================
=            Tool list            =
=================================*/

.o-tools {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.o-tools__toolType {
    --width: calc(var(--total-tools) * 1/2 * 100%);

    width: var(--width);
    max-width: 100%;
    padding-top: 2rem;
    margin-bottom: 1em;

    @media #{md("xs")} {
        --width: calc(var(--total-tools) * 1/3 * 100%);
    }

    @media #{md("sm")} {
        --width: calc(var(--total-tools) * 1/4 * 100%);
    }

    @media #{md("md")} {
        --width: calc(var(--total-tools) * 1/5 * 100%);
    }

    @media #{md("lg")} {
        --width: calc(var(--total-tools) * 1/6 * 100%);
    }
}

.o-tools__title {
}

.o-tools__list {
    display: flex;
    flex-wrap: wrap;
    margin-top: 1rem;
}

.o-tools__item {
    flex: 1 1 0;
    padding-top: 1em;
    padding-bottom: 1em;
    min-width: calc(1/3 * 100%);

    @media #{md("xs")} {
        min-width: calc(1/5 * 100%);
    }

    @media #{md("sm")} {
        min-width: calc(1/6 * 100%);
    }
}

.o-tools__logo {
    width: 100%;
    height: 3.25em;

    img {
        width: auto;
        height: 100%;
    }
}

.o-tools__name {
    margin: 0.75rem 0 .25rem;
}

.o-tools__count {
    color: $color-secondary;
}


