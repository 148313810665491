
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                
























































































































































.c-accordion {

    &.is-open {

        .c-accordion__content {
            pointer-events: initial;
        }
    }

    &.-upside-down {
        display: flex;
        flex-direction: column;

        .c-accordion__header {
            order: 2
        }

        .c-accordion__container {
            order: 1
        }
    }
}


.c-accordion__header {
    // padding-top: 1em;
    // padding-bottom: 1em;
    user-select: none;
    cursor: pointer;

    &:hover,
    .is-open & {
    }

    &:hover {
    }

    &:hover,
    .is-open &:hover {
    }

    .is-open & {
    }
}

.c-accordion__container {
    height: 0;
    opacity: 0;
    overflow: hidden;
}


.c-accordion__content {
    // max-height: 400px;
    padding-bottom: 1em;
    pointer-events: none;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

