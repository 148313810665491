
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                




























































.b-awards__list {
    display: grid;
    grid-gap: 2em var(--grid-gutter);
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media #{md("sm")} {
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    @media #{md("md")} {

        .b-content.-large & {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

.b-awards__item {
}

.b-awards__logo {
    display: block;
    margin-bottom: 1rem;

    img {
        width: auto;
        height: 1rem;
    }
}

.b-awards__link {
    display: block;
    margin-top: .5rem;
    color: $color-secondary;
}

