
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


























































































































































































































































































/*==============================
=            Header            =
==============================*/

$logo-animation-duration: .6;


.l-header {
    z-index: 1000;
    position: fixed;
    top: 1em;
    left: 0;
    width: $grid-width;
    padding-right: var(--grid-gutter);
    padding-left: var(--grid-gutter);

    @media #{md("sm")} {

        &:after {
            content: "reduce";
            display: none;
        }

        &.is-reduced {

            .l-header__logo {
                transform: translate(-.75em, 0);
                transition: transform .3s $out-sine .6s;
            }

            .l-header__bg {
                width: $header-height--desktop;
                transition: width .8s $in-out-expo .3s;
            }

            .l-header__inner {
                pointer-events: none;
            }

            .l-nav__item {
                opacity: 0;
                transform: translate(0, -.5em) skew(-10deg);

                @for $i from 1 to 4 {

                    &:nth-last-child(#{$i}) {
                        $i: $i - 1;
                        transition: all #{.2}s $in-quad #{$i/10 + .1}s;
                    }
                }
            }

            .l-nav__link.is-active:after {
                transform: scale(1, 0);
                transition: transform .3s $in-quad;
            }

            .l-header__mandate {
                opacity: 0;
                transform: translate(0, -.25em) skew(-10deg);
                transition: all .2s $in-quad 0s;
            }
        }
    }
}

.l-header__wrapper {
    display: flex;
    width: 100%;
    max-width: calc(#{$grid-max-width} - 2.5rem * 2);
    height: $header-height;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25em;
    padding-left: 1.25em;

    @media #{md("sm")} {
        height: $header-height--desktop;
        padding-right: .75em;
        padding-left: .75em;
        border-radius: calc(#{$header-height--desktop}/2);
        // overflow: hidden;
    }
}

.l-header__bg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-color: $header-bg;
    border-radius: calc(#{$header-height}/2);
    box-shadow: $box-shadow;

    @media #{md("sm")} {
        transition: width .6s $out-expo #{$logo-animation-duration}s;
    }
}

.l-header__inner {
    display: flex;
    align-items: center;

    @media #{md("sm", "max")} {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-top: $header-height;
        padding-right: inherit;
        padding-bottom: 2em;
        padding-left: inherit;
        background-color: $header-bg;
        border-radius: calc(#{$header-height}/2);
        pointer-events: none;
        transform: scale(1, 0);
        transition: transform .3s $out-quint .3s;
        transform-origin: 50% calc(#{$header-height}/2);
        //overflow: hidden;

        &:before {
            @include pseudo-el;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: inherit;
            box-shadow: $box-shadow;
            opacity: 0;
        }

        .nav-is-open & {
            pointer-events: initial;
            transform: scale(1);
            transition: transform .6s $out-quint 0s;

            &:before {
                opacity: 1;
                transition: opacity .4s ease-out .2s;
            }
        }
    }

    @media #{md("sm")} {
        position: static;
        width: 100%;
    }
}

.l-header__logo {
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: $header-height;
    height: $header-height;
    border-radius: 50%;
    background-color: inherit;

    span {
        display: block;
        padding: .7em;
    }

    .js-magnet-hit {
        --hit-x: .5em;
    }

    @media #{md("sm", "max")} {
        margin-left: -1.25em;
    }

    @media #{md("sm")} {
        width: $header-height--desktop;
        height: $header-height--desktop;
        background-color: $header-bg;
        transform: translate(0);
        transition: transform .3s $out-sine #{$logo-animation-duration}s;
    }
}

.l-header__btn {
    z-index: 5;
    margin-left: auto;
    overflow: hidden;

    // Fix safari bug
    .is-safari &,
    .is-mobile-safari &,
    .is-webkit & {

        .c-btn__ripple {
            top: 96%;
            border-radius: $header-height;
            transition: border-radius .2s ease-out;
        }

        &:hover {

            .c-btn__ripple {
                border-radius: $header-height;
                transition: border-radius .6s ease-out;
            }
        }
    }

    @media #{md("sm")} {
        display: none;
    }
}

.l-header__mandate {

    .c-btn__icon + .c-btn__label {
        margin-left: .5rem;
    }

    @media #{md("sm", "max")} {
        opacity: 0;
        transform: scale(.9);
        transform-origin: 50% 0;

        .nav-is-open & {
            opacity: 1;
            transform: translate(0) scale(1);
            transition: all .2s $out-quad .4s;
        }
    }

    @media #{md("sm")} {
        margin-left: auto;
        background-color: transparent;
        opacity: 1;
        transform: translate(0) skew(0);
        transition: all .2s $out-quad #{.6 + $logo-animation-duration}s;
    }
}



/*----------  Main Navigation  ----------*/

.l-nav {

    @media #{md("sm", "max")} {
        width: 100%;
    }

    @media #{md("sm")} {
        position: absolute;
        top: 0;
        left: calc((100% - 45%)/2);
        display: flex;
        width: 45%;
        height: 100%;
        align-items: center;
        overflow: hidden;
    }

    @media #{md("md")} {
        left: calc((100% - 50%)/2);
        width: 50%;
    }
}

.l-nav__list {

    @media #{md("sm")} {
        display: flex;
        width: 100%;
        justify-content: space-around;
    }

    @media #{md("md")} {
        justify-content: space-between;
    }
}

.l-nav__item {

    @media #{md("sm", "max")} {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        overflow: hidden;

        //opacity: 0;
        //transform: translate(0, 100%);
        // transition: var(--transition);

        .nav-is-open & {
            // opacity: 1;
            // transform: translate(0);
        }

        @for $i from 1 through 3 {
            &:nth-child(#{$i}) {
                $i: $i - 1;
                --transition: all .2s #{$in-quad} #{(2 - $i)/30}s;

                .nav-is-open & {
                    --transition: all .4s #{$out-quad} #{.2 + $i/9}s;
                }
            }
        }
    }

    @media #{md("sm")} {
        opacity: 1;
        transform: translate(0) skew(0);

        @for $i from 1 to 4 {

            &:nth-child(#{$i}) {
                $i: $i - 1;
                transition: all #{.2}s $out-quad #{$i/6 + .1 + $logo-animation-duration}s;
            }
        }
    }
}

.l-nav__link {
    display: inline-block;
    padding-top: .5em;
    padding-bottom: .5em;
    font-size: 1.25em;

    @media #{md("sm", "max")} {
        opacity: 0;
        transform: translate(0, 1em);
        transition: var(--transition);

        .nav-is-open & {
            opacity: 1;
            transform: translate(0);
        }
    }

    @media #{md("sm")} {
        padding-top: 1em;
        padding-bottom: 1em;
        font-size: 0.8rem;

        &:after {
            @include pseudo-el($width: 2px, $height: #{$header-height--desktop/2}, $bg: currentColor);
            position: absolute;
            bottom: 100%;
            left: calc(50% - 2px/2);
            transform: scale(1, 0);
            transform-origin: 50% 0;
            transition: transform .4s $out-quad #{$logo-animation-duration}s;
        }

        &.is-active:after {
            transform: scale(1);
            transition: transform .4s $out-quad #{.4 + $logo-animation-duration}s;
        }
    }
}


