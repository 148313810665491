
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                

































































































.p-work {}

.p-work__cover {
    display: block;

    @media #{md("sm", "max")} {
        width: 100%;
        height: 0;
        padding-top: 100%;
        background-image: var(--image);
        background-position: 50% 50%;
        background-size: cover;

        img {
            display: none;
            visibility: hidden;
        }
    }

    @media #{md("sm")} {

        img {
            @include img;
        }
    }
}

.p-work__title:nth-child(2) {
    margin-top: .75rem;
}

.p-work__data {

    .t-sub {
        margin-bottom: .5rem;
    }

    @media #{md("md", "max")} {
        margin-top: 2em;
    }

    @media #{md("md")} {

        &:nth-child(n+3) {
            margin-top: 2em;
        }
    }
}


