
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                























































.p-home {}

.p-home__palm {
    position: absolute;
    top: 50px;
    right: 0;
    left: 0;
    bottom: 0;
    width: auto;
    height: auto;
    pointer-events: none;

    .o-asset {
        width: calc(1/2 * 100%);
        float: right;
        display: block;

        @media #{md("sm", "max")} {
            width: calc(2/3 * 100%);
        }
    }

    video {
        width: 70%;
        transform: translate3d(30%, 0, 0);
        float: right;
        display: block;

        @media #{md("sm", "max")} {
            width: 80%;
            transform: translate3d(20%, 5%, 0);
        }
    }
}

