
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


















































































































































































































































































































































































































































































































































.p-mandate {

    .c-btn-circle {
        z-index: 100;
        position: sticky;
        bottom: 1em;
        left: 50%;
        margin-top: 1em;
        transform: translate(-50%, 0);
    }

    .p-success {}

    .p-success__title {
        margin-bottom: .5em;
    }

    .p-success__sub {
        margin-bottom: 1.5em;
    }

    .p-success__btns {
        margin: -.5em;

        .c-btn {
            margin: .5em;
        }
    }

    @media #{md("xs")} {

        .c-btn-circle {
            left: 100%;
            transform: none;
        }
    }
}


/*================================
=            Formular            =
================================*/


.c-form {

    &:after {
        @include pseudo-el($height: vh(50));
        @include linear-gradient(to bottom, rgba(#fff, 0), #fff);
        z-index: 1;
        position: fixed;
        bottom: 0;
        left: 0;
        pointer-events: none;
    }
}

.c-form__item {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    color: $color-dark-light;
    transition: all .4s ease-in-out;

    &.is-open {
        padding-top: vh(5);
        padding-bottom: vh(5);
        color: $color-dark;
    }

    &.is-filled,
    &.is-open {

        .c-form__open,
        .o-input__text {
            opacity: 1;
            transform: rotate3d(1, 0, 0, 0) scale(1);
            transition: opacity .2s ease-out .2s, transform .2s ease-out .2s;
        }

        .c-form__label {
            opacity: 0;
            transform: rotate3d(-1, 0, 0, 90deg) scale(1, .7);
            transition: opacity .2s ease-in, transform .2s ease-in;
        }

        .o-input__item {
            z-index: 0;
            opacity: 1;
            transform: scale(1);

            @for $i from 1 to 10 {
                $i: $i - 1;

                &:nth-child(#{$i}) {
                    transition: all .2s $out-quad #{.2 + $i/10 * .5}s;
                }
            }
        }
    }

    @media #{md("sm")} {
        margin-top: 3em;
        margin-bottom: 3em;
    }
}

.c-form__el {
    display: inline-block;

    &.-textarea {
        width: 100%;
    }
}

.c-form__label {
    z-index: 1;
    // height: 100%;
    display: inline-block;
    pointer-events: none;
    user-select: none;
    transform-origin: 50% 0;
    transition: opacity .2s ease-out .2s, transform .2s ease-out .2s;
}

.c-form__open {
    position: absolute;
    left: 0;
    top: 1rem;
    font-family: ff("supline");
    font-size: .6rem;
    font-weight: 700;
    text-transform: uppercase;
    opacity: 0;
    transform: rotate3d(1, 0, 0, 90deg) scale(1, .7);
    transform-origin: 50% 100%;
    transition: opacity .2s ease-in 0s, transform .2s ease-in 0s;
}

.c-form__submit {
    margin-top: 2em;
}


/*----------  Inputs  ----------*/

.o-input {
    // margin-top: -2.5em;

    &.-textarea {

        .o-input__text {
            width: 100%;
            height: 6em;
        }
    }

    &.-radio {
    }

    &.-file {
        margin-top: 0;

        .o-input__label {
            display: inline-block;
        }
    }
}

.o-input__text {
    width: 100%;
    height: inherit;
    color: inherit;
    border: 0;
    background: none;
    opacity: 0;
    transform: rotate3d(1, 0, 0, 90deg) scale(1, .7);
}

.o-input__list {
    // display: flex;
    // flex-wrap: wrap;
    margin: -.2em 0;
}

.o-input__item {
    z-index: -1;
    display: inline-block;
    margin: .5em .7em;
    opacity: 0;
    transform: scale(.8);

    @for $i from 1 to 10 {
        $i: $i - 1;

        &:nth-child(#{$i}) {
            transition: all .2s $in-quad 0s;
        }
    }
}

.o-input__radio {
    display: none;

    &:checked + .o-input__label {
        --color-bg: #{$color-primary-light};
        --color-border: transparent;

        .c-btn__bg {
            // background-color: $color-primary-light;
        }

        &:before {
            // opacity: 0;
        }
    }
}

.o-input__label {
    display: block;
}

.o-input__file {
    display: none;
}


