
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                












































.b-website {}

.b-website__link {
    display: block;
    width: 100%;
    padding: 6rem;
    text-align: center;

    &:hover {

        .b-website__url:after {
            transform: scale(1);
            transform-origin: 0 0;
        }
    }

    @media #{md("sm", "max")} {
        padding: 4rem 2rem;
    }

    @media #{md("xs", "max")} {
        padding: 3rem 1rem;
    }
}

.b-website__label {
    display: block;
    margin-bottom: 0.25em;
}

.b-website__url {
    display: inline-block;

    &:after {
        @include pseudo-el($height: .1em, $bg: currentcolor);
        position: absolute;
        top: 100%;
        left: 0;
        transform: scale(0, 1);
        transform-origin: 100% 0;
        transition: transform 0.4s ease-in-out;
    }
}

