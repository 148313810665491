
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


















































.b-image-oversized {
}

.b-image-oversized__img {

    @media (min-width: $grid-max-width) {
        width: 100vw;
        left: calc( 50% - 50vw);
        height: vh(100);
        object-fit: cover;
        object-position: center;
        overflow: hidden;
    }
}

