
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                
























































.b-content-image {

    &.-rounded {

        .b-content-image__inner {
            max-width: 30rem;
            max-height: 30rem;
            margin-right: auto;
            margin-left: auto;
            border-radius: 50%;
        }
    }
}

.b-content-image__img {
    @include img;
}

