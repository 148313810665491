/*===============================
=            Section            =
===============================*/


.l-section {
	color: var(--color-text, #{$color-dark});
	background-color: var(--color-bg, #{$color-light});

	&.-header {
		margin-top: calc(-1 * (#{$header-height} + 2em));
	}

	@media #{md("sm")} {

		&.-header {
			margin-top: calc(-1 * (#{$header-height--desktop} + 4em));
		}
	}
}

.l-section__inner {
	--padding-y: 1.5rem;
	padding-top: var(--padding-y);
	padding-bottom: var(--padding-y);

	@media #{md("xs")} {
		--padding-y: 2.5rem;
	}

	@media #{md("sm")} {
		--padding-y: 4rem;
	}
}




/*----------  Background color  ----------*/

.-bg-dark {
	--color-bg: 	#{$color-dark};
	--color-text: 	#{ $color-light};

	.c-btn {
		--color-bg: transparent;
	}
}

.-bg-light {
	--color-bg: 	#{$color-light};
	--color-text: 	#{$color-dark};
}


.-bg-gradient-purple-red,
.-bg-gradient-grey-white,
.-bg-gradient-green-yellow {

	.c-btn {
		--color-bg: transparent;
	}

	&:after {
		@include pseudo-el();
		z-index: -1;
		position: absolute;
		top: 0;
		left: 0;
	}
}


.-bg-gradient-green-yellow:after {
	@include linear-gradient(0, rgba(123, 232, 170, 0.5) -12.19%, rgba(140, 232, 148, 0.43666) 1%, rgba(255, 230, 3, 0) 69.94%);
}
.-bg-gradient-purple-red:after {
	@include linear-gradient(0, rgba(39, 78, 229, 0.5) 0.08%, rgba(96, 62, 166, 0.345721) 21.64%, rgba(223, 25, 26, 0.00287799) 69.93%, rgba(224, 25, 25, 0) 69.94%, rgba(224, 25, 25, 0) 74.32%);
	opacity: .5;
}

.-bg-gradient-grey-white:after {
	@include linear-gradient(0, $color-grey-light, $color-light);
	opacity: .25;
}
