
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                











































































































































































/*----------  Main button  ----------*/

.c-btn {
    --color-bg:     transparent;
    --color-text:   #{$color-dark};
    --color-border: var(--color-text);
    --color-ripple: #{$color-primary-light};

    display: inline-block;
    height: 3em;
    padding-right: 1.5em;
    padding-left: 1.5em;
    color: $color-dark;
    text-align: center;
    text-decoration: none;
    background-color: var(--color-bg);
    border-radius: 10rem;
    cursor: none;
    transition: all .2s ease;

    .js-magnet-hit {
        --hit-x: -.25em;
    }

    &.-sm {
        height: 2.4em;
        padding-right: 1em;
        padding-left: 1em;

        .c-btn__inner {
            font-size: .75em;
        }
    }

    &.-light {
        --color-bg:     #{$color-light};
    }

    &.-dark {
        --color-bg:     #{$color-dark};
        --color-text:   #{$color-light};
        --color-border: transparent;
        --color-ripple: #{$color-light};

        &:hover {
            --color-text:   #{$color-dark};
        }
    }

    &.-primary-light {
        --color-bg:     #{$color-primary-light};
        --color-border: transparent;
        --color-ripple: #{$color-primary};
    }

    .-dark-mode .l-main & {

        &:not(.-dark):not(.-light):not(.-primary-light) {
            --color-bg:     transparent;
            --color-text:   #{$color-light};
            --color-border: #{$color-light};
            --color-ripple: #{$color-light};

            &:hover {
                --color-text:   #{$color-dark};
            }
        }
    }

    &.-burger {
        right: -1.25em;
        height: auto;
        height: $header-height;
        padding: .75em 2em;

        &:before {
            display: none;
        }

        .c-btn__icon {
            display: block;
            width: .8em;
            height: .6em;
            margin-left: 1em;

            span {
                position: absolute;
                left: 0;
                display: block;
                width: 100%;
                height: 1px;

                &:before {
                    @include pseudo-el($bg: currentColor);
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: transform .5s $in-out-sine .7s;
                }

                &:after {
                    @include pseudo-el($width: 120%, $bg: currentColor);
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: transform .7s $in-sine 0s;
                }

                &:nth-child(1) {
                    top: 0;
                    transform-origin: 0 50%;

                    &:before {
                        transform-origin: 0 50%
                    }

                    &:after {
                        transform: rotate(45deg) scale(0, 1);
                        transform-origin: 0 50%;
                    }
                }

                &:nth-child(2) {
                    bottom: 0;
                    transform-origin: 100% 50%;

                    &:before {
                        transform-origin: 100% 50%
                    }


                    &:after {
                        transform: rotate(-45deg) scale(0, 1);
                        transform-origin: 0 50%;
                    }
                }

            }

            .nav-is-open & {

                span {

                    &:before {
                        transform: scale(0, 1);
                        transition: transform .7s $in-sine 0s;
                    }

                    &:after {
                        transition: transform .5s $in-sine .7s;
                    }

                    &:nth-child(1):after { transform: rotate(45deg) scale(1) translate(0, -1px) }
                    &:nth-child(2):after { transform: rotate(-45deg) scale(1) translate(0, 1px) }
                }
            }
        }

    }

    &.is-active {

        .c-btn__bg:before {
            border-color: transparent;
        }

        .c-btn__ripple {
            transform: scale(1.5) !important;
        }

        .is-touch & {
            background-color: var(--color-ripple);
        }
    }

    &:active {

        .c-btn__label {
            transform: scale(.95);
            transition: transform .1s;
        }

        .c-btn__icon {
            transform: scale(.9);
            transition: transform .1s;
        }
    }
}

.c-btn__bg {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    left: 1px;
    display: block;
    width: auto;
    height: auto;
    background-color: var(--color-bg);
    border-radius: inherit;
    // Safari overflow bug fix
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    &:before {
        @include pseudo-el($width: auto, $height: auto);
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        left: 1px;
        border: 1px solid var(--color-border);
        border-radius: inherit
    }
}

.c-btn__ripple {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-radius: 50%;
    margin-top: -50%;
    background-color: var(--color-ripple);
    transform: scale(0);
    will-change: transform;

    .is-touch & {
        display: none;
    }
}

.c-btn__inner {
    position: static;
    display: flex;
    align-items: center;
    height: 100%;
    margin: auto;
    font-size: .9em;
    color: var(--color-text);
    border-radius: inherit;
}

.c-btn__icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    font-size: 1rem;
    transition: .2s ease;

    & + .c-btn__label {
        margin-left: .75rem;
    }
}

.c-btn__label {
}

.c-btn__after {
    position: static;
    width: 3rem;
    margin-right: -1.4rem;
    margin-left: 1em;

    &:before {
        @include pseudo-el($width: 1px, $height: calc(100% - 4px), $bg: currentColor);
        position: absolute;
        top: 2px;
        right: calc(3rem/2);
    }

    svg {
        width: 1rem;
        height: 1rem;
    }
}


/*----------  Circle button  ----------*/

.c-btn-circle {
    display: flex;
    width: 6em;
    height: 6em;

    &:hover {

        .c-btn-circle__bg {
            background-color: $color-btn-hover;
        }
    }

    &:active {

        .c-btn-circle__label {
            transform: scale(.95);
            transition: transform .1s;
        }

        .c-btn-circle__icon {
            transform: scale(.9);
            transition: transform .1s;
        }
    }
}

.c-btn-circle__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: inherit;
    height: inherit;
    background-color: $color-light;
    border-radius: 50%;
    box-shadow: $box-shadow-2;
    transition: background-color .3s;
}

.c-btn-circle__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.c-btn-circle__icon {

    & + .c-btn-circle__label {
        margin-top: .5em;
    }
}

.c-btn-circle__label {
    padding-right: 1em;
    padding-left: 1em;
    text-align: center;
    word-break: break-word;

    @extend .t-sub;
}

/*----------  Burger button  ----------*/


.c-btn-burger {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

.c-btn__text {
    display: flex;
    overflow: hidden;

    &.-open {

        .l {
            opacity: 1;
            transform: translate(0);

            @for $i from 1 through 4 {

                &:nth-last-child(#{$i}) {
                    $i: $i - 1;
                    transition: all #{.5 - $i/8}s $in-out-sine #{$i/8 + .2}s;
                }
            }
        }
    }

    &.-close {
        position: absolute;
        top: 0;
        right: 0;

        .l {
            opacity: 0;
            transform: translate(0, -100%);

            @for $i from 1 through 6 {

                &:nth-last-child(#{$i}) {
                    $i: $i - 1;
                    transition: all #{.5 - $i/12}s $in-sine #{$i/12}s;
                }
            }
        }

    }

    .l {
        display: block;
    }

    .nav-is-open & {

        &.-open {

            .l {
                opacity: 0;
                transform: translate(0, 100%);

                @for $i from 1 through 4 {

                    &:nth-child(#{$i}) {
                        $i: $i - 1;
                        transition: all #{.5 - $i/8}s $in-sine #{$i/8}s;
                    }
                }
            }
        }

        &.-close {

            .l {
                opacity: 1;
                transform: translate(0);

                @for $i from 1 through 6 {

                    &:nth-child(#{$i}) {
                        $i: $i - 1;
                        transition: all #{.5 - $i/18}s $in-out-sine #{$i/18 + .2}s;
                    }
                }
            }
        }
    }
}



.js-magnet-hit {
    --hit-x: -.5em;
    --hit-y: var(--hit-x);

    z-index: 10;
    position: absolute;
    top: var(--hit-y);
    right: var(--hit-x);
    bottom: var(--hit-y);
    left: var(--hit-x);
    display: block;
    width: auto;
    height: auto;
    border-radius: inherit;
}


