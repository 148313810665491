
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                















































.b-project-tools {}

.b-project-tools__list {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 1rem;
    margin-bottom: 2rem;

    @media #{md("sm")} {
        margin-top: 2rem;
        margin-bottom: 4rem;
    }

    @media #{md("md")} {

        .b-content.-large & {
            grid-template-columns: repeat(3, minmax(0, 1fr));
        }
    }

    @media #{md("lg")} {

        .b-content.-large & {
            grid-template-columns: repeat(4, minmax(0, 1fr));
        }
    }
}

// .b-project-tools__tool {
//     min-width: 8.5em;
// }


