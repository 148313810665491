
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                


































































.b-team {
    display: grid;
    grid-gap: var(--grid-gutter);

    @media #{md("xs")} {
        grid-template-columns: repeat(2, 1fr);
    }

    @media #{md("sm")} {
        grid-template-columns: repeat(3, 1fr);
    }
}

