
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                






























































































































































































/*===============================
=            Loader             =
===============================*/


.o-loader {
    z-index: -10;
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0s 1s, z-index 0s 1s;

    &.-firstload {
        transition: opacity 0s 1.4s, z-index 0s 1.4s;

        .o-loader__bg {
            transform: scale(1);
        }

        .o-loader__gradients {
            display: none;
        }

        &.is-loading.-animate {

            .o-loader__inner {
                transform: translate(0);
                transition: transform .4s $in-out-quad;
            }
        }
    }

    &.is-loading {
        z-index: 2000;
        opacity: 1;
        pointer-events: initial;
        transition: none;

        .o-loader__gradients {
            --animation-state: running;
        }
    }
}

.o-loader__inner {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    transform: translate(0, 100%);
    transition: transform .4s $out-quad;
}

.o-loader__icon {
    --size: 99vw;
    width: 100vw;
    margin-bottom: calc(-.01 * var(--size));
    will-change: transform;

    svg {
        display: block;
        width: var(--size);
        height: calc(var(--size) * (39/223));
    }

    &:nth-child(1) {
        animation: firstloadLogoLoop 4s linear infinite;
    }

    &:nth-child(2) {
        position: absolute;
        bottom: 0;
        left: 0;
        transform: translate(100%, 0);
        animation: firstloadLogoIn calc(4s/2) linear, firstloadLogoLoop 4s linear calc(4s/2) infinite;
    }
}



$color-gradient-red: rgba(#e01919, .5);
$color-gradient-blue: #274ee5;
$color-gradient-yellow: rgba(#ffe603, 1);
$color-gradient-green: #8ce894;

.o-loader__bg {
    --size: 0px;
    position: fixed;
    top: calc(50% - var(--size)/2);
    left: calc(50% - var(--size)/2);
    display: block;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    background-color: $color-light;
    overflow: hidden;
    transform: scale(0);
    transform-origin: 50% 50%;
    will-change: transform;

    &:after {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        @include linear-gradient(to bottom, $color-light, rgba(#fff, 0));
    }
}

.o-loader__gradients {
    --animation-state: paused;

    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;

    &:after,
    &:before {
        @include pseudo-el;
        position: absolute;
        top: 0;
        left: 0;
        background-size: auto 100%;
        background-repeat: repeat-x;
        opacity: 0;
    }

    &:before {
        @include linear-gradient(to bottom, $color-gradient-red, $color-gradient-blue);
        animation: loaderGradient 4s infinite var(--animation-state);
    }

    &:after {
        @include linear-gradient(to bottom, $color-gradient-yellow, $color-gradient-green);
        animation: loaderGradient 4s infinite var(--animation-state) calc(4s/2);
    }
}

@keyframes loaderGradient {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    60% {
        opacity: .5;
    }
    90% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}


@keyframes firstloadLogoIn {
    0% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(0);
    }
}

@keyframes firstloadLogoLoop {
    0% {
        transform: translate(0);
    }
    50% {
        transform: translate(-100%, 0);
    }
    50.0001% {
        transform: translate(100%, 0);
    }
    100% {
        transform: translate(0);
    }
}

