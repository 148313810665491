
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                





























































































































/*===================================
=            Member card            =
===================================*/


.c-card.-member {

    .c-card__asset {

        &:before {
            @include pseudo-el();
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;
            @include linear-gradient(to top, rgba($color-primary, .75), rgba(255, 255, 255, 0));
            transform: translate(0, 100%);
            transition: transform .3s ease-in-out;
        }

        img {
            transform: scale(1.1);
            transition: transform .3s ease-in-out;
        }

        &:hover {

            &:before {
                transform: translate(0);
                transition: transform .3s ease-out;
            }

            .c-card__social {
                opacity: 1;
                transition: opacity .3s ease-out;
            }

        }
    }
}

.c-card__social {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    transform: translate(0, -50%);
    opacity: 0;
    transition: opacity .3s ease-in-out;

    li {

        &:nth-child(n+2) {
            margin-left: .2em;
        }

    }

    a {
        display: flex;
        width: 2.25em;
        height: 2.25em;
        color: $color-light;
    }

    svg {
        display: block;
        width: .75em;
        height: .75em;
        margin: auto;
    }
}

.c-card__phone {
    display: block;
    margin-top: 1em;
}

.c-card__email {
    display: block;
    margin-top: .5em;
}


