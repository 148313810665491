
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                





























































.c-card.-instagram {
    background-color: $color-light;
    border-radius: .5em;
    box-shadow: $box-shadow;
    overflow: hidden;

    .c-card__media {

        video {
            display: block;
            width: 100%;
        }
    }

    .c-card__content {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: .75em 1em;
    }

    .c-card__title {
        font-size: .8rem;
    }
}

.c-card__palm {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 2.5em;
    height: 2.5em;
    margin-right: 1em;
    color: $color-light;
    background-color: $color-dark;
    border-radius: 50%;
}



