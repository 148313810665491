
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                





































































































































.o-wave {
    --height: 120px;

    z-index: 10;
    display: block;
    //position: absolute;
    //bottom: -1px;
    //left: 0;
    //width: 100%;
    max-width: none;
    height: var(--height);
    transform-origin: 50% 0;

    @include center-viewport;
    overflow: hidden;
    bottom: -1px;

    &.-top {
        top: -1px;
        bottom: auto;
        margin-top: calc(.5 * var(--height));
        backface-visibility: hidden;
        transform: rotateZ(180deg);
    }

    &.-bottom {
        margin-bottom: calc(.5 * var(--height));
    }

    svg {
        overflow: visible;
        width: 100%;
        height: inherit;
    }

    @media #{md("sm")} {
        --height: 200px;
    }

    path {
        fill: $color-light;
    }
}

