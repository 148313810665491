/*=================================
=            Selection            =
=================================*/

::-moz-selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}

::selection {
	text-shadow: none;
	color: $selection-color;
	background: $selection-bg;
}



/*=======================================
=            Global settings            =
=======================================*/


:root {
	--color-text: #{$color-dark};
	--color-bg: transparent;

	--color-btn-hover: #{$color-btn-hover};
	--color-light: #{$color-light};

    // Grid gutter
    @each $breakpoint in $breakpoints {
        $key: nth($breakpoint, 1);

        @media #{md($key)} {
            --grid-gutter: #{map-get($grid-gutters, $key)};
            --grid-gutter-half: calc(var(--grid-gutter)/2);
        }
    }
}

*,
*:after,
*:before {
	position: relative;
	outline: none;
}

html {
	font-size: 18px;
	// overflow: hidden;

	&.no-scroll {
		height: 100%;
		overflow: hidden;
	}

    // Better font rendering for mac
    &.is-mac-os {
        @include antialiased;
    }

	@media #{md("sm")} {
		font-size: 20px;
	}
}

body {
	color: var(--color-text);
 	// overflow-y: auto;
}
