
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                







































































.c-list {

    &.-border {

        .c-list__item {
            padding-top: 1rem;
            padding-bottom: 1rem;
            border-bottom: 1px solid rgba(30, 30, 30, 0.15);
        }
    }
}

.c-list__item {
}

.c-list__link {
    display: inline-block;
    padding-top: .3em;
    padding-bottom: .3em;

    &:after {
        @include pseudo-el($height: .1em, $bg: currentColor);
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.c-list__text {
}


