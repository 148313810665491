/*=======================================
=            Global settings            =
=======================================*/


// Colors
$color-dark         : #000000;
$color-dark-light   : rgba($color-dark, 0.2);
$color-light        : #ffffff;
$color-grey         : #1e1e1e;
$color-grey-light   : #c4c4c4;
$color-primary      : #edce3b;
$color-primary-light: #fef17b;
$color-secondary    : #4662cf;

$color-btn-hover    : $color-primary-light;

$font-color: $color-dark;
$selection-color    : $color-dark;
$selection-bg       : $color-primary-light;


// Shadows
$box-shadow     : 0 0 80px rgba(0, 0, 0, 0.05);
$box-shadow-2   : 0 0 80px rgba(0, 0, 0, 0.08);
$box-shadow-3   : 0 0 30px rgba(0, 0, 0, 0.07);
$box-shadow-4   : 0 0 100px rgba(0, 0, 0, 0.08);


// Header
$header-height          : 4.25em;
$header-height--desktop : 3.75em;
$header-bg              : $color-light;



/*==================================
=            Typography            =
==================================*/


$font-families: (
    "main": ("Graphik", "Helvetica Neue", Arial, sans-serif),
    "supline": ("Agrandir", "Helvetica Neue", Arial, sans-serif),
    "special": ("Rolling Pen", Georgia, serif),
);


$font-size: 16px;
$line-height: 1.4;

$font-dir: "/static/fonts/";

$fontfaces: (
    "Agrandir" "agrandir-black" 900 normal,
    "Rolling Pen" "rolling-pen" 400 normal,
    "Graphik" "graphik-light" 300 normal,
    "Graphik" "graphik-regular" 400 normal,
    "Graphik" "graphik-medium" 600 normal,
    "Graphik" "graphik-medium-italic" 600 italic,
    "Graphik" "graphik-bold" 700 normal,
    "Graphik" "graphik-black" 800 normal,
);


// $font-size-xxx-small        : 0.6rem; // 14px base on root 20px
// $font-size-xx-small         : 0.7rem; // 14px base on root 20px
// $font-size-x-small          : 0.8rem; // 16px base on root 20px
// $font-size-small            : 0.9rem; // 18px base on root 20px
// $font-size-regular          : 1rem; // 20px base on root 20px
// $font-size-medium           : 1.25rem; // 25px base on root 20px
// $font-size-large            : 2rem; // 40px base on root 20px
// $font-size-x-large          : 3rem; // 60px base on root 20px


// Accessibility
$accessible-size: 44px;


/*=================================================
=            Media queries breakpoints            =
=================================================*/


$breakpoints: (
    "xxs":     0,
    "xs":    480,
    "sm":    768,
    "md":    992,
    "lg":   1280,
    "xl":   1440,
    "xxl":  1920
);



/*============================
=            Grid            =
============================*/


$grid-width: 100%;
$grid-max-width: 1440px;
$grid-columns: 12;
$grid-gutters: (
    "xxs":   1.00rem,
    "xs":    1.00rem,
    "sm":    1.50rem,
    "md":    1.50rem,
    "lg":    2.50rem,
    "xl":   2.50rem,
    "xxl":  3.00rem
);


// Spaces
$spaces : (
    0: 0rem,
    1: 1rem,
    2: 2rem,
    3: 3rem,
    4: 4rem
);


/*===========================================
=            Images and SVG dirs            =
===========================================*/


$img-dir: "/img/layout/";
$svg-dir: "/assets/svg/layout/";



/*=================================
=            Z-indexes            =
=================================*/


$layers: (
    "goku"     : 9000,
    "modal"    : 200,
    "dropdown" : 100,
    "default"  : 1,
    "limbo"    : -999
);



/*==============================
=            Easing            =
==============================*/


$in-quad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$out-quad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$in-out-quad: cubic-bezier(0.455, 0.030, 0.515, 0.955);

$in-cubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$out-cubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$in-out-cubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);

$in-quart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$out-quart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$in-out-quart: cubic-bezier(0.770, 0.000, 0.175, 1.000);

$in-quint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$out-quint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$in-out-quint: cubic-bezier(0.860, 0.000, 0.070, 1.000);

$in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$in-out-sine: cubic-bezier(0.445, 0.050, 0.550, 0.950);

$in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);

$in-circ: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$out-circ: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$in-out-circ: cubic-bezier(0.785, 0.135, 0.150, 0.860);

$in-back: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$out-back: cubic-bezier(0.175, 00.885, 0.320, 1.275);
$in-out-back: cubic-bezier(0.680, -0.550, 0.265, 1.550);

$out-back-t: cubic-bezier(0.535, 1.650, 0.635, 1.005);
