
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                



































































































.l-wrapper {

    &.-dark-mode {
        --color-text: #{$color-light};
        --color-bg: #{$color-dark};

        .l-main {
            background-color: var(--color-bg);
        }
    }
}

.l-main {
    padding-top: calc(#{$header-height} + 2em);

    @media #{md("sm")} {
        padding-top: calc(#{$header-height--desktop} + 4em);
    }
}

