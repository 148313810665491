
                    @import './src/assets/scss/base/variables.scss';
                    @import './src/assets/scss/tools/functions.scss';
                    @import './src/assets/scss/tools/mixins.scss';
                    @import './src/assets/scss/layout/typography.scss';
                




































































































































































































































.no-cursor * {
    cursor: none !important;
}


/*==============================
=            Cursor            =
==============================*/

.o-cursor {
    z-index: 1000;
    position: fixed;
    top: -2.5rem;
    left: -2.5rem;
    pointer-events: none;
    // backface-visibility: hidden;
    display: none;


    .no-cursor & {
        display: block;
        will-change: transform;
    }

    &.has-content {

        .o-cursor__inner {
            transform: scale(1);
            transition: all .3s $in-out-quad;

            &:before {
                transform: scale(1);
                transition: all .2s $out-quad .1s;
                transition: all .3s $out-quad 0s;
            }
        }

        .o-cursor__label {
            transform: rotate(0) skew(0);
            transition: all .3s $out-sine .1s;
        }
    }

    &.-light {

        .o-cursor__inner {
            --cursor-bg: #{$color-light};
        }
    }

    &.-hover {

        .o-cursor__inner {
            --cursor-bg: radial-gradient(#{$color-primary}, rgba(255, 255, 255, 0));

            opacity: .5;
            transform: scale(.5);
            transition: all .2s $in-out-quad;
        }
    }

    &.-hidden {

        .o-cursor__inner {
            transform: scale(0);
            transition: transform .2s $out-quad, background-color .1s $out-quad;
        }
    }


    &.-rotate {

        .o-cursor__content {
            animation: anim-cursor-rotate 4s linear infinite;
        }
    }

    @media (prefers-reduced-motion: reduce) {

        .o-cursor__inner {
            transition: none;
        }

        &.-rotate .o-cursor__content {
            animation: none;
        }
    }
}

.o-cursor__inner {
    --cursor-bg: #{$color-dark};

    display: flex;
    justify-content: center;
    align-items: center;
    width: 5rem;
    height: 5rem;
    background: var(--cursor-bg);
    border-radius: 50%;
    box-shadow: $box-shadow;
    transform: scale(0.12);
    transition: all .2s $in-out-quad;
    will-change: transform;

    &:before {
        @include pseudo-el($width: auto, $height: auto, $bg: $color-light);
        position: absolute;
        top: -1px;
        right: -1px;
        bottom: -1px;
        left: -1px;
        border-radius: inherit;
        transform: scale(0);
    }
}

.o-cursor__content {
    display: block;
}

.o-cursor__label {
    display: block;
    width: 100%;
    margin: auto;
    padding-right: 1em;
    padding-left: 1em;
    text-align: center;
    wprd-break: break-all;
    transform: rotate(10deg) skew(10deg);
}


/*----------  Rotation  ----------*/

@keyframes anim-cursor-rotate {
    0% {
        transform: rotate(0);
    }
    100% {
        transform: rotate(1turn);
    }
}


